.popup_container {
    z-index: 100;
    display: flex;
    position: fixed;
    height: 75vh;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 8rem;
    background-color: #04151F;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    position: fixed;
    width: 70%;
    justify-content: flex-end;
}

.close {
    color: white;
    margin: 10px 27px 10px 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.close:hover {
    color: #2C78FA;
}

.proj_info {
    display: flex;
    flex-direction: column;
    font-family: var(--font-1);
    color: white;
    padding-left: 70px;
    padding-right: 70px;
    margin-top: 30px;
}

.title {
    font-size: 40px;
    font-weight: 500;
}

.lang_info {
    margin: 15px 0 30px 0;
    font-style: italic;
}

.lang {
    font-weight: 500;
}

.info {
    margin-left: 5px;
}

.proj_details {
    background: #030e14;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.bio {
    background: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 20px;
}

.line {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #04151F;
    margin: 35px 0 35px 0;
    padding: 0;
}

.section_header {
    display: flex;
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 15px;
}

.section_info {
    margin-left: 30px;
    margin-bottom: 35px;
    line-height: 1.2;
}

ul li {
    padding: 10px 0 10px 0;
}

.git1 {
    display: flex;
    align-content: center;
    text-align: center;
    justify-content: center;
    margin-top: 30px;
}

.git1:hover {
    color: #2C78FA;
}

.github1 {
    font-size: 15px;
    margin-left: 5px;
}