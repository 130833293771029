.mysite_projects {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5rem;
    padding-bottom: 4rem;
    align-content: center;
}

.projects {
    position: relative;
    font-size: 50px;
    font-family: var(--font-1);
    padding-bottom: 4rem;
}

.projects_row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 40px 0 40px;
    justify-content: center;
    max-width: 100vw;
}

.proj {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 23rem;
    margin-bottom: 60px;
}

.proj_img{
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.proj_img-pic {
    width: 100%
}

.imgtext {
    position: absolute;
    z-index: 50;
    max-width: 23rem;
    font-family: var(--font-1);
    color: #c5d3e2;
    font-size: 2rem;
    font-weight: 650;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 1.4;
}

.proj_button, .git_button {
    display: flex;
    margin-bottom: 5px;
    padding: 12px 0 12px 0;
    font-size: 16px;
    font-family: var(--font-1);
    font-weight: 400;
    justify-content: center;
    cursor: pointer;
}

.git_button {
    background-color: black;
    color: white;
}

.proj_button {
    background-color: #04151F;
    color: white;
}

.proj_button-text, .git_button-text {
    padding-top: 1px;
}

.proj_button:hover, .git_button:hover {
    transform: scale(1.05);
}

#eye, #git {
    margin-right: 10px;
}

.git_link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
}

span{
    margin-right: 5px;
    margin-left: 5px;
}

.popup {
    position: absolute;
    display: flex;
    cursor: pointer;
}



@media screen and (max-width: 1050px) {
    .projects_row1 {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .spotify_project {
        position: relative;
        left: 0;
        right: 0;
        margin: 0 0 0 0;
        padding-bottom: 0rem;
    }

    .website_project {
        position: relative;
        left: 0;
        padding-top: 4rem;
        margin: 0 0 0 0;
    }

    .projects_row2 {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .chatbot_project {
        position: relative;
        right: 0;
        padding-bottom: 0rem;
        margin: 0 0 0 0;
    }

    .webserver_project {
        position: relative;
        left: 0;
        padding-top: 4rem;
        margin: 0 0 0 0;
    }
}

@media screen and (max-width: 800px) {
    .proj_img {
        justify-content: center;
        text-align: center;
    }
}
@media screen and (max-width: 550px) {

    .imgtext {
        max-width: 340px;
    }
}

@media screen and (max-width: 400px) {

    .imgtext {
        max-width: 280px;
        font-size: 1.7rem;
    }
}

@media screen and (max-width: 350px) {
    
    .imgtext {
        max-width: 240px;
        font-size: 1.5rem;
    }
}