#navbar {
    position: fixed;
    top:0;
    width: 100%;
    overflow: hidden;
    z-index: 9999;
    transition: 0.35s;
    max-width: 100vw;
}

#navbar span:hover {
    color:#2C78FA;
    text-shadow: 0 0 5px #2C78FA;
    transform: scale(1.08);
}

.mysite_navbar-links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mysite_navbar-links_container {
    display: flex;
    font-family: var(--font-2);
    font-weight: 500;
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.mysite_navbar-links_container span + span {
    margin-left: 5rem;
}

.mysite_navbar-socials {
    display: none;
}

#navbar.sticky {
    background-color: rgb(29, 29, 29);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#navbar.sticky span {
    color:rgb(227, 227, 227)
}

#navbar.sticky span:hover {
    color:#2C78FA
}

#navbar.sticky .mysite_navbar-links_container {
    margin-top: 0;
}

#navbar.sticky .mysite_navbar-socials {
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
}

#navbar.sticky .mysite_navbar-socials a:hover {
    color: #2C78FA;
    transform: scale(1.1);
}

#navbar.sticky .nav-linkedin,
.nav-twitter,
.nav-instagram,
.nav-strava {
    color: rgb(227, 227, 227);
}

#navbar.sticky .mysite_navbar-socials a + a {
    margin-left: 0.7rem;
}

.mysite_navbar-menu-area {
    display: none;
}


@media screen and (max-width: 800px) {
    .mysite_navbar-links_container {
        display: none;
    }

    #navbar.sticky .mysite_navbar-socials {
        display: none;
    }

    .mysite_navbar-menu-area {
        display: flex;
        flex-direction: column;
        justify-content: right;
        align-items: flex-end;
        margin: 1rem 1rem 0 0;
    }

    .mysite_navbar-menu_container-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #444;
        border-radius: 5px;
        color: white;
        font-family: var(--font-1);
        font-size: 1.3rem;
        line-height: 3rem;
        padding: 1rem 1rem 1rem 1rem;
    }

    .mysite_navbar-menu_container-links p:hover{
        color: #2C78FA;
    }

    .mysite_navbar-menu-open {
        display: flex;
        margin-top: 0.5rem;
    }

    .mysite_navbar-menu-area svg {
        width: 2rem;
        height: 2rem;
    }

    #navbar.sticky{
        background: none;
        padding: 0 0 0 0;
    }

}


@media screen and (max-width: 550px) {
    .mysite_name {
        position: relative;
        left: -6.5rem;
    }

    #navbar {
        padding-top: 1rem;
    }
}