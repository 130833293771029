* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

.App {
  max-width: 100vw;
  max-height: 1000px;
}

a {
    color: unset;
    text-decoration: none;
}
.popups_container {
  z-index: 100;
  position: absolute;
  text-align: center;
  width: 8%;
  margin: auto;
  padding: 0;
}

.main_background {
  background: url("./assets/nycdusk.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.main_navbar {
  z-index: 999;
}

.about_page {
  background-color: #c5d3e2;
  background-size: cover;
}

.skills_page {
  background-color: rgb(44, 120, 250);
  background-size: cover;
}

.projects_page {
  background-color: #c5d3e2;
  background-size: cover;
}

.footer_page {
  background-color: rgb(136, 195, 231);
  background-size: cover;
}


.gradient-text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section_padding {
    padding: 4rem 6rem;
}

.section_margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @media screen and (max-width: 700px) {
    .section_padding {
        padding: 4rem;
    }
    .section_margin {
        margin: 4rem;
    }

  }


