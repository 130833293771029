@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Raleway:wght@200;300;400;500;600;700&display=swap');

:root {

  --font-1: 'Raleway', sans-serif;
  --font-2: 'Montserrat', sans-serif;
  --mysite_name: linear-gradient(89.97deg, #000000 1.84%, #0c24a0 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #f8825b -13.86%, #F49867 99.55%);
  
  --color-bg: #c5d3e2;
  --color-footer : #dce3ea;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}