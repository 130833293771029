.mysite_about-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mysite_about {
    display: flex;
    align-items: center;
    padding: 0 5vw 0 5vw;
}

.mysite_about-title {
    font-size: 2.5rem;
    font-family: var(--font-1);
    margin: 5rem 0 2rem 0;
}

.mysite_flic {
    width: 100%;
    max-width: 23vw;
    height: auto;
    border-radius: 100%;
}

.mysite_about-info {
    margin-left: 5vw;
    align-content: end;
    font-family: var(--font-1);
}

.mysite_about-name {
    font-size: 1.8rem;
}

.mysite_about-details {
    font-size: 1.2rem;
    font-weight: 300;
    font-style: italic;
    margin: 0.3rem 0 1rem 0;
}

.mysite_about-introduction,
.mysite_about-introduction2 { 
    font-size: 1.1rem;
    padding-bottom: 7px;
    font-weight: 400;
    line-height: 1.5rem;
}

.mysite_about-interests {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5rem;
}

.mysite_about-socials {
    margin: 3rem 0 4rem 0;
}

.mysite_about-socials a+a {
    margin-left: 2rem;
}

.mysite_about-svgs {
    width: 2.2rem;
    height: 2.2rem;
}

.mysite_about-socials svg:hover {
    color: #2C78FA;
    transform: scale(1.1);
}

@media screen and (max-width: 800px) {

    .mysite_about {
        flex-direction: column;
    }

    .mysite_flic {
        margin: 1rem 0 2rem 0;
        max-width: 35vw;
    }

    .mysite_about-info {
        margin: 0 2rem 0 2rem;
    }

    .mysite_about-introduction, .mysite_about-introduction2, 
    .mysite_about-interests {
        font-size: 1rem;
    }
}

@media screen and (max-width: 550px) {

    .mysite_flic {
        max-width: 70vw;
        margin-bottom: 2.5rem;
    }

    .mysite_about-name {
        font-size: 1.5rem;
    }

    .mysite_about-details {
        font-size: 1rem;
    }

    .mysite_about-interests, .mysite_about-introduction,
    .mysite_about-introduction2 {
        font-size: 0.8rem;
        line-height: 1.1rem;
    }
}