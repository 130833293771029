.mysite_skills {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mysite_skills-title {
    color: white;
    font-size: 2.5rem;
    font-family: var(--font-1);
    margin: 5rem 0 3rem 0;
}

.mysite_skills-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 4rem 0 4rem;
    justify-content: center;
    align-items: flex-start;
    column-gap: 5rem;
    row-gap: 4rem;
    margin-bottom: 5rem;
}

.mysite_skills-skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;    
    max-width: 18rem;
}

.mysite_skills-logo {
    background-color: white;
    border-radius: 100%;
    padding: 1.2vw;
    width: 6.5vw;
    height: 6.5vw;
}

.mysite_skills-name {
    color:white;
    font-family: var(--font-1);
    font-size: 2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
    font-weight: 600;
    text-align: center;
}

.mysite_skills-blurb {
    text-align: center;
    color: white;
    font-family: var(--font-1);
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.4rem;
}

@media screen and (max-width: 1050px){
    .py_logo {
        position: relative;
        right: 4rem;
        left: 0;
    }

    .javascript {
        align-self: center;
        position: relative;
        left: 3rem;
        max-width: 20rem;
    }

    .api {
        padding-right: 1rem;
        padding-left: 1rem;
        position: relative;
        top: 0.5rem;
        max-width: 20rem;
    }

    .react {
        position: relative;
        left: 0.2rem;
        max-width: 20rem;
    }
}

@media screen and (max-width: 935px){
    .react {
        max-width: 15rem;
        position: relative;
        left: -2rem;
        top: -2.8rem;
    }

    .api {
        max-width: 15rem;
        position: relative;
        top: 1rem;
    }

    .javascript {
        max-width: 15rem;
        position: relative;
        left:0
    }
}


@media screen and (max-width: 550px) {

    .skills_row1 {
        flex-direction: column;
        padding-top: 1rem;
    }

    .py_logo {
        position: relative;
        left: 0;
        right: 0;
        align-items: center;
        justify-content: center;
    }
    .python,
    .java,
    .clang {
        max-width: 30rem;
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 0 0 4rem 0;
        margin: 0 0;
    }

    .skills_row2 {
        flex-direction: column;
        padding-top: 1rem;
    }

    .react,
    .javascript {
        max-width: 30rem;
        padding: 0 0 4rem 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0 0;
    }
}