.popup_container {
    z-index: 100;
    display: flex;
    position: fixed;
    height: 75vh;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 8rem;
    background-color: #04151F;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    position: fixed;
    width: 70%;
    justify-content: flex-end;
}

.close {
    color: white;
    margin: 10px 27px 10px 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.close:hover {
    color: #2C78FA;
}

.proj_info {
    display: flex;
    flex-direction: column;
    font-family: var(--font-1);
    color: white;
    padding-left: 70px;
    padding-right: 70px;
    margin-top: 30px;
}

.title {
    font-size: 40px;
    font-weight: 500;
}

.lang_info {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 80px 0 30px 0;
    font-style: italic;
    font-size: 25px;
}

.lang {
    font-weight: 500;
}

.info {
    margin-left: 5px;
}

.git3 {
    display: flex;
    align-self: center;
    text-align: center;
    justify-content: center;
    margin-top: 30px;
    background-color: #020c11;
    padding: 10px 10px 10px 10px;
    width: 120px;
    border-radius: 10px;
}

.git3:hover {
    color: #2C78FA;
    transform: scale(1.1);
}

.github3 {
    font-size: 20px;
    margin-left: 5px;
}