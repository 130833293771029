.mysite_header {
    display: flex;
    position: relative;
    height: 100vh;
    align-items: center;
    background-color: #444;
    opacity: 90%;
}

.mysite_header-content {
    display: flex;
    align-items: center;
}

.mysite_header-intro {
    font-family: var(--font-1);
    color: white;
    margin-left: 6vw;
}

.mysite_header-name {
    display: flex;
}

.mysite_header-name h1 {
    font-size: 5rem;
    font-weight: 500;
}

.mysite_header-lastName {
    color: #2C78FA;
    margin-left: 1rem;
}

.mysite_header-bio {
    font-size: 1.3rem;
    font-weight: 200;
    background-color: #28282862;
    border-radius: 0.15rem;
    padding: 0.15rem 0.5rem 0.15rem 0.5rem;
    margin-top: 0.4rem;
    width: fit-content;
}

.mysite_header-links {
    display: flex;
    align-items: center;
    margin-top: 1.4rem;
}

.mysite_header-social {
    display: flex;
    align-items: center;
}

.mysite_header-svgs {
    width: 1.8rem;
    height: 1.8rem;
}

.mysite_header a + a {
    margin-left: 0.8rem;
}

.mysite_header-social:hover {
    color: #2C78FA;
    transform: scale(1.1);
}

@media screen and (max-width: 550px) {

    .mysite_header-name h1 {
        font-size: 13vw;
        font-weight: 500;
    }

    .mysite_header-bio {
        font-size: 3vw;
    }
}